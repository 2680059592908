<template>
     <div class="row">
                  <div class="col-lg-8">
                     <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog blog-detail">
                        <div class="iq-card-body">
                           <div class="image-block">
                              <img src="@/assets/images/blog/01.jpg" class="img-fluid rounded w-100" alt="blog-img">
                           </div>
                           <div class="blog-description mt-3">
                              <h5 class="mb-3 pb-3 border-bottom">There are many variations of passages.</h5>
                              <div class="blog-meta d-flex align-items-center mb-3">
                                 <div class="date mr-4"><i class="ri-calendar-2-fill text-primary pr-2"></i>2 Weeks ago</div>
                                 <div class="like mr-4"><i class="ri-thumb-up-line text-primary pr-2"></i>20 like</div>
                                 <div class="comments mr-4"><i class="ri-chat-3-line text-primary pr-2"></i>82 comments</div>
                                 <div class="share"><i class="ri-share-forward-line text-primary pr-2"></i>share</div>
                              </div>
                              <p>Voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                              <a href="#" tabindex="-1">Read More <i class="ri-arrow-right-s-line"></i></a>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-4">
                     <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog-post">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="header-title">
                              <h4 class="iq-card-title">New Post</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <ul class="list-inline p-0 mb-0 mt-2">
                              <li class="mb-3">
                                 <div class="d-flex align-items-top pb-3 border-bottom">
                                    <div class="col-md-5">
                                       <div class="image-block">
                                          <img src="@/assets/images/blog/03.jpg" class="img-fluid rounded w-100" alt="blog-img">
                                       </div>
                                    </div>
                                    <div class="col-md-7">
                                       <div class="blog-description pl-2">
                                          <div class="date mb-1"><a href="#" tabindex="-1">4 Weeks ago</a></div>
                                          <h6 class="mb-2">All the Lorem Ipsum generators</h6>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                              <li class="mb-3">
                                 <div class="d-flex align-items-top pb-3 border-bottom">
                                    <div class="col-md-5">
                                       <div class="image-block">
                                          <img src="@/assets/images/blog/04.jpg" class="img-fluid rounded w-100" alt="blog-img">
                                       </div>
                                    </div>
                                    <div class="col-md-7">
                                       <div class="blog-description pl-2">
                                          <div class="date mb-1"><a href="#" tabindex="-1">3 Weeks ago</a></div>
                                          <h6 class="mb-2">All the Lorem Ipsum generators</h6>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                              <li class="mb-3">
                                 <div class="d-flex align-items-top pb-3 border-bottom">
                                    <div class="col-md-5">
                                       <div class="image-block">
                                          <img src="@/assets/images/blog/05.jpg" class="img-fluid rounded w-100" alt="blog-img">
                                       </div>
                                    </div>
                                    <div class="col-md-7">
                                       <div class="blog-description pl-2">
                                          <div class="date mb-1"><a href="#" tabindex="-1">2 Weeks ago</a></div>
                                          <h6 class="mb-2">All the Lorem Ipsum generators</h6>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                              <li class="mb-3">
                                 <div class="d-flex align-items-top pb-3 border-bottom">
                                    <div class="col-md-5">
                                       <div class="image-block">
                                          <img src="@/assets/images/blog/06.jpg" class="img-fluid rounded w-100" alt="blog-img">
                                       </div>
                                    </div>
                                    <div class="col-md-7">
                                       <div class="blog-description pl-2">
                                          <div class="date mb-1"><a href="#" tabindex="-1">1 Week ago</a></div>
                                          <h6 class="mb-2">All the Lorem Ipsum generators</h6>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                              <li class="mb-3">
                                 <div class="d-flex align-items-top pb-3 border-bottom">
                                    <div class="col-md-5">
                                       <div class="image-block">
                                          <img src="@/assets/images/blog/07.jpg" class="img-fluid rounded w-100" alt="blog-img">
                                       </div>
                                    </div>
                                    <div class="col-md-7">
                                       <div class="blog-description pl-2">
                                          <div class="date mb-1"><a href="#" tabindex="-1">3 days ago</a></div>
                                          <h6 class="mb-2">All the Lorem Ipsum generators</h6>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                              <li>
                                 <div class="d-flex align-items-top">
                                    <div class="col-md-5">
                                       <div class="image-block">
                                          <img src="@/assets/images/blog/08.jpg" class="img-fluid rounded w-100" alt="blog-img">
                                       </div>
                                    </div>
                                    <div class="col-md-7">
                                       <div class="blog-description pl-2">
                                          <div class="date mb-1"><a href="#" tabindex="-1">2 Days ago</a></div>
                                          <h6 class="mb-2">All the Lorem Ipsum generators</h6>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-12">
                     <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog user-comment">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="header-title">
                              <h4 class="iq-card-title">User Comment</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <div class="row">
                              <div class="col-lg-12">
                                 <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog">
                                    <div class="iq-card-body">
                                       <div class="d-flex align-items-center">
                                          <div class="user-image mb-3">
                                             <img class="avatar-80 rounded" src="@/assets/images/user/01.jpg" alt="#" data-original-title="" title="">
                                          </div>
                                          <div class="ml-3">
                                             <h5>Gurt Mistrioty</h5>
                                             <p>Web Developer</p>
                                          </div>
                                       </div>
                                       <div class="blog-description">
                                          <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident.</p>
                                          <div class="d-flex align-items-center justify-content-between mb-2">
                                             <a href="#" class="comments"><i class="ri-chat-3-fill pr-2"></i>82 comments</a>
                                             <span class="font-size-12 text-warning">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half-o"></i>
                                             </span>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-lg-12 pl-5">
                                 <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog">
                                    <div class="iq-card-body">
                                       <div class="d-flex align-items-center">
                                          <div class="user-image mb-3">
                                             <img class="avatar-80 rounded" src="@/assets/images/user/02.jpg" alt="#" data-original-title="" title="">
                                          </div>
                                          <div class="ml-3">
                                             <h5>Tom Cruise</h5>
                                             <p>Web Designer</p>
                                          </div>
                                       </div>
                                       <div class="blog-description">
                                          <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum Many desktop publishing packages and web page editors now use Lorem Ipsum.</p>
                                          <div class="d-flex align-items-center justify-content-between mb-2">
                                             <a href="#" class="comments"><i class="ri-chat-3-fill pr-2"></i>98 comments</a>
                                             <span class="font-size-12 text-warning">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                             </span>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-lg-12">
                                 <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog">
                                    <div class="iq-card-body">
                                       <div class="d-flex align-items-center">
                                          <div class="user-image mb-3">
                                             <img class="avatar-80 rounded" src="@/assets/images/user/03.jpg" alt="#" data-original-title="" title="">
                                          </div>
                                          <div class="ml-3">
                                             <h5>Walter Hucko</h5>
                                             <p>Web Designer</p>
                                          </div>
                                       </div>
                                       <div class="blog-description">
                                          <p>TThere are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                                          <div class="d-flex align-items-center justify-content-between mb-2">
                                             <a href="#" class="comments"><i class="ri-chat-3-fill pr-2"></i>28 comments</a>
                                             <span class="font-size-12 text-warning">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half-o"></i>
                                                <i class="fa fa-star-o"></i>
                                             </span>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-lg-12 pl-5">
                                 <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog">
                                    <div class="iq-card-body">
                                       <div class="d-flex align-items-center">
                                          <div class="user-image mb-3">
                                             <img class="avatar-80 rounded" src="@/assets/images/user/04.jpg" alt="#" data-original-title="" title="">
                                          </div>
                                          <div class="ml-3">
                                             <h5>Tom Cruise</h5>
                                             <p>Web Manager</p>
                                          </div>
                                       </div>
                                       <div class="blog-description">
                                          <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.randomised words which don't look even slightly believable variations of passages.</p>
                                          <div class="d-flex align-items-center justify-content-between mb-2">
                                             <a href="#" class="comments"><i class="ri-chat-3-fill pr-2"></i>98 comments</a>
                                             <span class="font-size-12 text-warning">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half-o"></i>
                                             </span>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-lg-12">
                                 <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog">
                                    <div class="iq-card-header d-flex justify-content-between">
                                       <div class="header-title">
                                          <h4 class="iq-card-title">Your Comment</h4>
                                       </div>
                                    </div>
                                    <div class="iq-card-body">
                                       <form>
                                          <div class="form-group">
                                             <label for="email1">Email address:</label>
                                             <input type="email" class="form-control" id="email1">
                                          </div>
                                          <div class="form-group">
                                             <label for="pwd">Password:</label>
                                             <input type="password" class="form-control" id="pwd">
                                          </div>
                                          <div class="form-group">
                                             <label for="exampleFormControlTextarea1">Comment</label>
                                             <textarea class="form-control" id="exampleFormControlTextarea1" rows="4"></textarea>
                                          </div>
                                          <div class="checkbox mb-3">
                                             <label>
                                                <input type="checkbox">Remember me</label>
                                          </div>
                                          <button type="submit" class="btn btn-primary">Submit</button>
                                          <button type="submit" class="btn iq-bg-danger">Cancel</button>
                                       </form>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
</template>
<script>
export default {
  name: 'BlogDetail'
}
</script>
